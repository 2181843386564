.drag-and-drop {
  margin-top: 30px;
  padding: 32px;
  background-color: #DAE8FC; // rgba(0, 0, 255, 0.4);
  border: 1px dashed blue;
  border-radius: 16px;
  width: 300px;
  cursor: pointer;

  &.dragging {
    background-color: #cbe1ff;
  }
}
