.upload-screen {
  padding: 16px;
}

.upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    display: inline-block;
    cursor: pointer;
  }
}

.upload-icon {
  width: 50px;
  margin-bottom: 10px;
}

.loading-text {
  margin: 16px 0;
}

.error-text {
  margin: 16px 0;
  color: red;
}
